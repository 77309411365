import React, { useRef, useImperativeHandle, forwardRef, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPlayingSongId } from '../store/sharedSlice';
import { Button, Typography } from '@mui/material';
interface AudioPlayerCompetibleProps {
  audioUrl: string;
  autoplay?: boolean;
  _id?: string;
  sx?: object;
  timeStyle?: object;
  buttonStyle?: object;
  buttonStyleIsPlaying?: object;
  preload?: string;
  onPlay?: () => void;
  duration?: number;
}
export interface AudioPlayerCompetibleHandle {
  play: () => void;
  pause: () => void;
  getCurrentTime: () => void;
}
function isPlaying(audioElement: HTMLAudioElement | null) {
  return audioElement && !audioElement.paused && audioElement.currentTime > 0 && !audioElement.ended;
}
const AudioPlayerCompetible = forwardRef<AudioPlayerCompetibleHandle, AudioPlayerCompetibleProps>(({
  audioUrl,
  autoplay = false,
  _id = '',
  sx = {},
  timeStyle = {},
  buttonStyle = {},
  buttonStyleIsPlaying = {},
  preload = 'none',
  onPlay = () => {},
  duration: loadedDuration
}, ref) => {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const audiotag = useMemo(() => <audio ref={audioRef} src={audioUrl} controls={true} autoPlay={autoplay} style={{
    flex: 1
  }} preload={preload}>
          Your browser does not support the audio element.
        </audio>, [audioUrl]);
  const dispatch = useDispatch();
  const {
    playingSongId
  } = useSelector((state: any) => state.shared);
  useEffect(() => {
    if (isPlaying(audioRef?.current)) {
      if (playingSongId !== _id && playingSongId != null) {
        audioRef?.current?.pause?.();
      }
    }
  }, [playingSongId]);
  useEffect(() => {
    if (audioRef?.current) {
      let ref = audioRef?.current;
      const onPlay1 = () => {
        dispatch(setPlayingSongId(_id));
        onPlay();
      };
      ref.addEventListener('play', onPlay1);
      return () => {
        ref.removeEventListener('play', onPlay1);
      };
    }
  }, [audioRef?.current]);

  // Expose play/pause functions to parent components
  useImperativeHandle(ref, () => ({
    play: () => {
      if (audioRef.current) {
        audioRef.current.play();
      }
    },
    pause: () => {
      if (audioRef.current) {
        audioRef.current.pause();
      }
    },
    getCurrentTime: () => {
      return audioRef?.current?.currentTime || 0;
    },
    getDuration: () => {
      return audioRef?.current?.duration || 0;
    }
  }));
  return audiotag;

  //     return (
  //   //      <>
  //             {audiotag}
  //             {

  //             <Typography variant="body2" sx={{ textAlign: 'center', fontSize: '12px' }} >
  //                 A Competible player, <Button variant="text" href={'#'}
  //                     sx={{
  //                         m: '0px', p: '0px', width: 'auto', display: 'inline', lineHeight: 'inherit', fontSize: 'inherit',
  //                         verticalAlign: 'baseline',

  //                         backgroundColor: 'white',

  //                         '&:hover': {
  //                             backgroundColor: 'white',
  //                             textDecoration: 'underline'
  //                         }
  //                     }}
  //                     onClick={() => {
  //                         (audioRef.current as any)?.load()
  //                     }}>reload player</Button>
  //             </Typography>

  //             }
  //       //  </>

  //     );
});
export default AudioPlayerCompetible;