import React, { useRef, useState, useImperativeHandle, forwardRef, useEffect } from 'react';
import AudioPlayerMP3 from './AudioPlayerMP3';
import AudioPlayerCompetible from './AudioPlayerCompetible';
import AudioPlayer_orig from './AudioPlayer';
interface AudioPlayerProps {
  audioUrl: string;
  _id?: string;
  sx?: object;
  autoplay?: boolean;
  preload?: string;
  timeStyle?: object;
  buttonStyle?: object;
  buttonIconStyle?: object;
  sliderStyle?: object;
  buttonStyleIsPlaying?: object;
  onPlay?: () => void;
  duration?: number;
}
export interface AudioPlayer5Handle {
  play: () => void;
  pause: () => void;
  getCurrentTime: () => number;
  getDuration: () => number;
}
const NewAudioPlayer: React.ForwardRefRenderFunction<AudioPlayer5Handle, AudioPlayerProps> = ({
  audioUrl,
  _id = '',
  sx = {},
  preload = 'none',
  autoplay = false,
  timeStyle = {},
  buttonStyle = {},
  buttonIconStyle = {},
  sliderStyle = {},
  buttonStyleIsPlaying = {},
  onPlay = () => {},
  duration
}, ref) => {
  const playerRef = useRef<any | null>(null);
  const [hasAudioContext, setHasAudioContext] = useState<boolean | null>(null);
  useEffect(() => {
    setHasAudioContext(typeof window !== 'undefined' && (typeof window.AudioContext !== 'undefined' || typeof (window as any).webkitAudioContext !== 'undefined') && typeof window.WebAssembly !== 'undefined');
  }, []);
  useImperativeHandle(ref, () => ({
    play: () => {
      playerRef.current?.play?.();
    },
    pause: () => {
      playerRef.current?.pause?.();
    },
    getCurrentTime: () => {
      return playerRef.current?.getCurrentTime?.() || 0;
    },
    getDuration: () => {
      return playerRef.current?.getDuration?.() || 0;
    }
  }));
  if (!audioUrl) return null; // Return null or a loader until the client-side check is done

  const PlayerComponent = audioUrl.endsWith('.mp3') || audioUrl.includes('/audio-stream') ? hasAudioContext ? AudioPlayerMP3 : AudioPlayerCompetible : AudioPlayer_orig;
  if (hasAudioContext === null) return null; // Return null or a loader until the client-side check is done

  return <PlayerComponent ref={playerRef} audioUrl={audioUrl} autoplay={autoplay} _id={_id} sx={sx} timeStyle={timeStyle} buttonStyle={buttonStyle} buttonIconStyle={buttonIconStyle} sliderStyle={sliderStyle} buttonStyleIsPlaying={buttonStyleIsPlaying} onPlay={onPlay} duration={duration} data-sentry-element="PlayerComponent" data-sentry-component="NewAudioPlayer" data-sentry-source-file="NewAudioPlayer.tsx" />;
};
export default forwardRef(NewAudioPlayer);