import React, { useState, useRef, useEffect, useImperativeHandle, forwardRef, useCallback } from 'react';
import { Button, Slider, Box, Typography, CircularProgress, IconButton } from '@mui/material';
import WasmMP3AudioPlayer from './WasmMP3AudioPlayer'; // Import the class
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { setPlayingSongId } from '../store/sharedSlice';
import { useDispatch, useSelector } from 'react-redux';
interface AudioPlayerMP3Props {
  audioUrl: string;
  autoplay?: boolean;
  disableBtn?: boolean;
  _id: string;
  sx: object;
  timeStyle: object;
  buttonStyle: object;
  buttonIconStyle: object;
  buttonStyleIsPlaying: object;
  sliderStyle: object;
  preload?: string;
  onPlay?: () => void;
  duration?: number;
}
export interface AudioPlayerMP3Handle {
  play: () => void;
  pause: () => void;
  getCurrentTime: () => number;
}
const AudioPlayerMP3: React.ForwardRefRenderFunction<AudioPlayerMP3Handle, AudioPlayerMP3Props> = ({
  audioUrl,
  autoplay = false,
  disableBtn = false,
  _id = '',
  sx = {},
  timeStyle = {},
  buttonIconStyle = {},
  buttonStyle = {},
  buttonStyleIsPlaying = {},
  sliderStyle = {},
  preload = 'none',
  onPlay = () => {},
  duration: loadedDuration
}, ref) => {
  const [pageHref, setPageHref] = useState<string>(() => location.href);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [duration, setDuration] = useState<number>(loadedDuration || 0);
  const [isError, setIsError] = useState<boolean>(false); // New state for error handling
  const playerClassInstanceRef = useRef<WasmMP3AudioPlayer | null>(null);
  const updatePlaybackState = useCallback((thatPlayer: WasmMP3AudioPlayer): void => {
    setCurrentTime(thatPlayer.getCurrentTime());
    const d = thatPlayer.getDuration();
    //if (d != duration) {
    setDuration(d);
    //}
    const p = thatPlayer.isPlaying();
    setIsPlaying(p);
    if (p !== isPlaying && p) {
      if (onPlay) {
        onPlay();
      }
    }
    setIsDownloading(thatPlayer.getDownloading());
  }, [isPlaying, duration]);
  useEffect(() => {
    if (playerClassInstanceRef.current) {
      playerClassInstanceRef.current.destroy();
      setCurrentTime(0);
      setDuration(0);
      setIsPlaying(false);
    }
    try {
      playerClassInstanceRef.current = new WasmMP3AudioPlayer(audioUrl, updatePlaybackState, autoplay, preload);
      setIsError(false);
    } catch (error) {
      setIsError(true);
      console.error(error);
    }
    return () => {
      playerClassInstanceRef.current?.destroy();
    };
  }, [audioUrl]);
  useEffect(() => {
    if (pageHref !== location.href && playerClassInstanceRef.current) {
      playerClassInstanceRef.current.destroy();
      setCurrentTime(0);
      setDuration(0);
      setIsPlaying(false);
    }
  }, [location.href]);
  const dispatch = useDispatch();
  const {
    playingSongId
  } = useSelector((state: any) => state.shared);
  useEffect(() => {
    if (isPlaying) {
      if (playingSongId !== _id && playingSongId != null) {
        playerClassInstanceRef.current?.pause();
        if (playerClassInstanceRef.current?.getDownloading()) playerClassInstanceRef.current?.reset();
        setIsPlaying(false);
      }
    }
  }, [playingSongId]);
  const handlePlayPause = (): void => {
    if (isError) {
      // Retry mechanism: recreate the player when in error state
      let autoplay = true;
      try {
        playerClassInstanceRef.current = new WasmMP3AudioPlayer(audioUrl, updatePlaybackState, autoplay, preload);
        setIsError(false); // Reset error state if retry is successful
      } catch (error) {
        console.error('Retry failed: ', error);
      }
    } else {
      if (isPlaying) {
        playerClassInstanceRef.current?.pause();
      } else {
        dispatch(setPlayingSongId(_id));
        playerClassInstanceRef.current?.play();
        setIsPlaying(true);
      }
      setIsPlaying(!isPlaying);
    }
  };
  const handleSeek = (_: Event, newValue: number | number[]): void => {
    setCurrentTime(Array.isArray(newValue) ? newValue[0] : newValue);
    playerClassInstanceRef.current?.seek(Array.isArray(newValue) ? newValue[0] : newValue);
  };

  // Expose play/pause and getCurrentTime functions to parent components
  useImperativeHandle(ref, () => ({
    play: () => {
      dispatch(setPlayingSongId(_id));
      playerClassInstanceRef.current?.play();
      setIsPlaying(true);
    },
    pause: () => {
      playerClassInstanceRef.current?.pause();
      setIsPlaying(false);
    },
    getCurrentTime: () => {
      return currentTime;
    },
    getDuration: () => {
      return isDownloading ? 0 : duration;
    }
  }));
  const formatTime = (time: number): JSX.Element => {
    const minutes = Math.floor(time / 60).toString();
    const seconds = Math.floor(time % 60).toString().padStart(2, '0');
    return <span style={{
      whiteSpace: 'nowrap',
      display: 'inline-block'
    }} data-sentry-component="formatTime" data-sentry-source-file="AudioPlayerMP3.tsx">
        <span style={{
        textAlign: 'center',
        width: '0.65em',
        display: 'inline-block'
      }}>
          {minutes.charAt(0)}
        </span>
        {minutes.length > 1 && <span style={{
        textAlign: 'center',
        width: '0.65em',
        display: 'inline-block'
      }}>
            {minutes.charAt(1)}
          </span>}
        <span style={{
        textAlign: 'center',
        width: '0.25em',
        display: 'inline-block'
      }}>
          {':'}
        </span>
        <span style={{
        textAlign: 'center',
        width: '0.65em',
        display: 'inline-block'
      }}>
          {seconds.charAt(0)}
        </span>
        <span style={{
        textAlign: 'center',
        width: '0.65em',
        display: 'inline-block'
      }}>
          {seconds.charAt(1)}
        </span>
      </span>;
    // return `${minutes.charAt(0)}:${seconds}`;
  };
  return <Box sx={{
    width: '100%',
    textAlign: 'center',
    margin: 'auto',
    ...sx
  }} data-sentry-element="Box" data-sentry-component="AudioPlayerMP3" data-sentry-source-file="AudioPlayerMP3.tsx">
      <Box sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }} data-sentry-element="Box" data-sentry-source-file="AudioPlayerMP3.tsx">
        <button style={{
        width: '48px',
        height: '48px',
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: disableBtn ? 'default' : 'pointer',
        backgroundColor: isError ? '#ccc' : isPlaying ? '#EF7484' : '#849EB2',
        color: 'white',
        ...buttonStyle,
        ...(isPlaying ? buttonStyleIsPlaying : {})
      }} onClick={handlePlayPause}>
          {isPlaying ? <PauseIcon style={{
          width: '50px',
          ...buttonIconStyle
        }} /> : <PlayArrowIcon style={{
          width: '50px',
          ...buttonIconStyle
        }} />}
        </button>
        <Typography variant="body2" sx={{
        textAlign: 'right',
        margin: '0 10px',
        width: '4rem',
        ...timeStyle
      }} data-sentry-element="Typography" data-sentry-source-file="AudioPlayerMP3.tsx">
          {formatTime(currentTime)}
        </Typography>
        <Slider value={currentTime} min={0} max={duration} step={0.01} onChange={handleSeek} sx={{
        flexGrow: 1,
        '& .MuiSlider-thumb': {
          width: 16,
          height: 16
        },
        // '& .MuiSlider-rail': {
        //   color: '#FFF',
        //   height: '3px',
        //   opacity: 0.85,
        // },

        ...sliderStyle
      }} data-sentry-element="Slider" data-sentry-source-file="AudioPlayerMP3.tsx" />

        <Typography variant="body2" sx={{
        textAlign: 'left',
        margin: '0 10px',
        width: '3rem',
        ...timeStyle
      }} data-sentry-element="Typography" data-sentry-source-file="AudioPlayerMP3.tsx">
          {isDownloading ? <CircularProgress size={14} sx={{
          ml: '3px',
          mt: '-3px',
          color: 'pimary.main',
          verticalAlign: 'middle'
        }} /> : formatTime(duration)}
        </Typography>
      </Box>
    </Box>;
};
export default forwardRef(AudioPlayerMP3);