import { MPEGDecoder } from 'mpg123-decoder';

function debounce<T extends (...args: any[]) => void>(
  func: T,
  wait: number
): (...args: Parameters<T>) => void {
  let timeoutId: ReturnType<typeof setTimeout> | undefined;

  return (...args: Parameters<T>): void => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      func(...args);
    }, wait);
  };
}

export default class WasmMP3AudioPlayer {
  private audioContext: AudioContext | null = null;
  private audioBuffer: AudioBuffer | null = null;
  private sourceNode: AudioBufferSourceNode | null = null;
  private startTime: number = 0;
  private offset: number = 0;
  private intervalId: NodeJS.Timeout | null = null;
  private duration: number = 0;
  private currentTime: number = 0;
  private durationInSeconds: number = 5 * 60; // 5 minutes
  private sampleRate: number = 48000; // 48kHz
  private numberOfChannels: number = 2;
  private loading: boolean = true;
  private playAfterLoad: boolean = false;
  private downloading: boolean = false;
  private preload: string = 'none';
  private audioUrl: string = '';

  private timerCallback: (currentTime: WasmMP3AudioPlayer) => void;
  private currentSampleIndex: number = 0; // To track where to place the next chunk of decoded data
  private abortController: AbortController | null = null;

  constructor(
    audioUrl: string,
    timerCallback: (currentTime: WasmMP3AudioPlayer) => void,
    autoplay = false,
    preload: string = 'none'
  ) {
    this.timerCallback = debounce(timerCallback, 100);
    this.preload = autoplay ? 'auto' : preload;
    this.playAfterLoad = autoplay;
    this.audioUrl = audioUrl;
    if (audioUrl) {
      if (this.preload == 'auto') {
        this.preload = 'done';
        this.loadAudio(this.audioUrl).catch((e) => {
          console.log('player', e.stack);
        });
      }
    }
  }

  private async loadAudio(audioUrl: string): Promise<void> {
    // console.log('loadAudio', audioUrl);
    this.downloading = true;
    this.abortController = new AbortController();
    const { signal } = this.abortController;

    try {
      const response = await fetch(audioUrl, { signal, cache: 'force-cache' });
      const reader = response.body?.getReader();
      const decoder = new MPEGDecoder();
      await decoder.ready;

      // Pre-allocate an AudioBuffer for 5 minutes of audio at 48kHz and 2 channels

      this.audioContext = new (window.AudioContext ||
        (window as any).webkitAudioContext)();

      if (!this.audioContext) {
        throw new Error('AudioContext not supported');
      }

      const startAndUpdateSampleRateAndChannels = (
        sampleRate: number,
        numberOfChannels: number
      ) => {
        if (this.audioBuffer) return true;
        if (sampleRate < 8000) return false;
        this.sampleRate = sampleRate;
        this.numberOfChannels = numberOfChannels;
        this.audioBuffer = this.audioContext!.createBuffer(
          this.numberOfChannels,
          this.sampleRate * this.durationInSeconds, // Total samples per channel
          this.sampleRate
        );
        return true;
      };

      while (true) {
        const { done, value } = (await reader?.read()) || {};
        if (done || !value) {
          this.downloading = false;
          this.timerCallback(this);
          break;
        }

        try {
          const decoded = decoder.decode(value);
          // console.log('decoded ', decoded)
          const {
            channelData,
            samplesDecoded,
            sampleRate: chunkSampleRate,
          } = decoded;

          let hasSampleRate = startAndUpdateSampleRateAndChannels(
            chunkSampleRate,
            channelData.length
          );
          if (!hasSampleRate) continue;

          // Check if the buffer needs to be grown
          if (
            this.currentSampleIndex + samplesDecoded >
            this.audioBuffer!.length
          ) {
            this.growBuffer();
          }

          channelData.forEach((channel, index) => {
            if (this.audioBuffer) {
              const audioBufferChannel = this.audioBuffer.getChannelData(index);
              audioBufferChannel.set(channel, this.currentSampleIndex);
            }
          });

          this.currentSampleIndex += samplesDecoded; // Advance the index by the number of samples decoded in this chunk
          this.duration = this.currentSampleIndex / this.sampleRate; // Calculate actual duration based on decoded samples
          this.loading = false;

          try {
            if (this.playAfterLoad) {
              this.playAfterLoad = false;
              this.play();
            }
          } catch (e) {
            console.log('autoplay failed', e);
          }
        } catch (e) {
          if (e.message.includes('Insufficient data')) {
            continue;
          } else {
            throw e;
          }
        }
      }
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.error('Error during streaming or decoding:', error);
      }
    } finally {
      this.downloading = false;
    }
  }
  public reset(): void {
    // Abort the download if in progress
    this.pause();
    this.pause();
    if (this.downloading && this.abortController) {
      this.abortController.abort();
    }
    setTimeout(() => {
      // Stop playback and clear resources
      this.preload = 'none';
      this.audioBuffer = null;
      this.currentSampleIndex = 0;
      this.startTime = 0;
      this.offset = 0;
      this.duration = 0;
      this.loading = true;
      this.downloading = false;
      this.timerCallback(this);
    }, 500);

    if (this.audioContext) {
      this.audioContext
        .close()
        .catch((e) => console.error('Error closing audio context:', e));
      this.audioContext = null;
    }

    console.log('Player reset to initial state.');
  }
  private growBuffer() {
    if (!this.audioContext) return;
    if (!this.audioBuffer) return;
    const additionalLength = this.sampleRate * this.durationInSeconds;

    // Create a new buffer with additional length
    const newBuffer = this.audioContext.createBuffer(
      this.audioBuffer.numberOfChannels,
      this.audioBuffer.length + additionalLength,
      this.audioBuffer.sampleRate
    );

    // Copy the existing data to the new buffer
    for (
      let channel = 0;
      channel < this.audioBuffer.numberOfChannels;
      channel++
    ) {
      const oldData = this.audioBuffer.getChannelData(channel);
      const newData = newBuffer.getChannelData(channel);

      // Copy the existing samples
      for (let i = 0; i < oldData.length; i++) {
        newData[i] = oldData[i];
      }
    }

    // If the sourceNode is null, there's no need to create and play it
    if (!this.sourceNode) {
      return;
    }

    // Calculate the current playback time
    const currentPlaybackTime =
      (this.audioContext?.currentTime || 0) - this.startTime;

    // Disconnect the old source node
    this.sourceNode.disconnect();
    this.sourceNode.stop(); // Stop the old sourceNode

    // Replace the old buffer with the new buffer
    this.audioBuffer = null;
    this.audioBuffer = newBuffer;

    // Create a new source node and connect it to the new buffer
    this.sourceNode = this.audioContext.createBufferSource();
    this.sourceNode.buffer = this.audioBuffer;
    this.sourceNode.connect(this.audioContext.destination);

    // Set the new startTime based on the current playback position
    this.startTime = this.audioContext.currentTime - currentPlaybackTime;

    // Start the new source node at the correct position to ensure seamless playback
    this.sourceNode.start(0, currentPlaybackTime);
  }

  public play(seek = false): void {
    if (this.preload === 'none') {
      this.preload = 'done';
      this.loadAudio(this.audioUrl);
    }
    if (this.loading) {
      this.playAfterLoad = true;
      return;
    }
    const playingEnded = this.duration === this.currentTime;
    if (playingEnded && !seek) {
      this.pause();
      this.offset = 0;
      this.currentTime = 0;
    }
    if (this.sourceNode) return; // already playing
    if (!this.audioContext || !this.audioBuffer) return;

    this.sourceNode = this.audioContext.createBufferSource();
    this.sourceNode.buffer = this.audioBuffer;
    this.sourceNode.connect(this.audioContext.destination);
    this.sourceNode.start(0, this.offset);

    this.startTime = this.audioContext.currentTime - this.offset;

    this.intervalId = setInterval(() => {
      this.updateCurrentTime();
    }, 200);
  }

  public isPlaying(): boolean {
    return !!this.sourceNode;
  }

  public pause(): void {
    if (this.loading) {
      this.playAfterLoad = false;
      return;
    }

    if (this.sourceNode) {
      this.sourceNode.stop();
      this.offset = (this.audioContext?.currentTime || 0) - this.startTime;
      this.sourceNode = null;
    }

    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  public seek1(time: number): void {
    this.pause();
    this.offset = time;
    this.currentTime = time;
    this.play(true);
  }

  public seek(time: number): void {
    const seekOffset = time - this.getCurrentTime();
    const durationOfTransition = Math.abs(seekOffset) / 1000; // Adjust this to make the transition smoother

    if (durationOfTransition > 0) {
      const initialPlaybackRate = this.sourceNode?.playbackRate.value || 1;
      const targetPlaybackRate = Math.sign(seekOffset) * 10; // Speed up to 10x in the direction of the seek

      this.easePlaybackRate(
        initialPlaybackRate,
        targetPlaybackRate,
        durationOfTransition,
        () => {
          this.pause();
          this.offset = time;
          this.currentTime = time;
          this.play(true);

          // Restore normal playback rate
          this.easePlaybackRate(targetPlaybackRate, 1, durationOfTransition);
        }
      );
    } else {
      this.pause();
      this.offset = time;
      this.currentTime = time;
      this.play(true);
    }
  }

  private easePlaybackRate(
    fromRate: number,
    toRate: number,
    duration: number,
    callback?: () => void
  ): void {
    if (!this.sourceNode) return;

    const startTime = this.audioContext?.currentTime || 0;
    const step = () => {
      const currentTime = this.audioContext?.currentTime || 0;
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / duration, 1);
      const easedProgress = this.easeInOutQuad(progress);

      const newRate = fromRate + (toRate - fromRate) * easedProgress;
      if (this.sourceNode) {
        this.sourceNode.playbackRate.value = newRate;
      }

      if (progress < 1) {
        requestAnimationFrame(step);
      } else if (callback) {
        callback();
      }
    };

    step();
  }

  private easeInOutQuad(t: number): number {
    return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
  }

  private updateCurrentTime(): void {
    if (!this.audioContext) return;

    const elapsed = this.audioContext.currentTime - this.startTime;
    if (elapsed >= this.duration) {
      this.pause();
      this.currentTime = this.duration;
      if (this.downloading)
        setTimeout(() => {
          this.timerCallback(this);
          this.play();
        }, 500);
    } else {
      this.currentTime = elapsed;
    }
    this.timerCallback(this);
  }

  public getCurrentTime(): number {
    return this.currentTime;
  }

  public getDuration(): number {
    return this.duration;
  }

  public getDownloading(): boolean {
    return this.downloading;
  }

  public destroy(): void {
    try {
      this.pause();
    } catch (e) {
      console.log(e);
    }
    this.audioBuffer = null;
    try {
      this.audioContext?.close().catch((e) => {
        console.log('destroy player', e);
      });
    } catch (e) {
      console.log(e);
    }
  }
}
